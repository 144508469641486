@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ProductSinglePg .Navbar .header {
  position: static;
  background-color: #AF9E75;
}
.ProductSinglePg .Navbar .header ul li a {
  color: white !important;
}
@media all and (max-width: 768px) {
  .ProductSinglePg .Navbar .header ul li a {
    color: white !important;
  }
}
.ProductSinglePg .Navbar .header.active ul li a {
  color: white !important;
}
@media all and (max-width: 768px) {
  .ProductSinglePg .Navbar .header.active ul li a {
    color: white !important;
  }
}

.ProductDetails .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .ProductDetails .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ProductDetails .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ProductDetails .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ProductDetails .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ProductDetails .container {
    padding: 50px 14px;
  }
}
.ProductDetails .container .row {
  display: flex;
  justify-content: space-between;
  gap: 10px 40px;
}
@media all and (max-width: 992px) {
  .ProductDetails .container .row {
    flex-direction: column;
  }
}
.ProductDetails .container .row .left-col .content {
  padding-bottom: 40px;
}
.ProductDetails .container .row .left-col .content .title {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  color: #C12328;
}
@media all and (max-width: 1200px) {
  .ProductDetails .container .row .left-col .content .title {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .ProductDetails .container .row .left-col .content .title {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .ProductDetails .container .row .left-col .content .title {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .ProductDetails .container .row .left-col .content .title {
    font-size: 24px;
  }
}
.ProductDetails .container .row .left-col .content .brand {
  position: relative;
  top: -16px;
}
.ProductDetails .container .row .left-col .content .brand span {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.ProductDetails .container .row .left-col .content a {
  text-decoration: none;
  font-size: 20px;
  color: blue;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  display: inline-block;
}
.ProductDetails .container .row .left-col .content a:hover {
  text-decoration: underline;
}
.ProductDetails .container .row .left-col .content a span {
  font-size: 16px;
  color: #9A5939;
  text-decoration: none;
}
.ProductDetails .container .row .left-col .img-container {
  display: flex;
  height: 300px;
}
@media all and (max-width: 1200px) {
  .ProductDetails .container .row .left-col .img-container {
    height: 260px;
  }
}
.ProductDetails .container .row .left-col .img-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ProductDetails .container .row .left-col .img_wrap {
  width: 60px;
  height: 60px;
  border: 1px solid #eee;
  cursor: pointer;
  padding: 2px;
}
@media all and (max-width: 576px) {
  .ProductDetails .container .row .left-col .img_wrap {
    width: 40px;
    height: 40px;
  }
}
.ProductDetails .container .row .left-col .img_wrap img {
  width: 100%;
  object-fit: cover;
}
.ProductDetails .container .row .left-col .img-display {
  padding: 0 6px;
}
.ProductDetails .container .row .left-col .img-display img {
  width: 500px !important;
  height: 100% !important;
  object-fit: contain !important;
}
@media all and (max-width: 1200px) {
  .ProductDetails .container .row .left-col .img-display img {
    width: 400px !important;
  }
}
@media all and (max-width: 576px) {
  .ProductDetails .container .row .left-col .img-display img {
    width: 300px !important;
  }
}
.ProductDetails .container .row .left-col .active {
  border: 2px solid #DDC7AA;
}
.ProductDetails .container .row .right-col {
  background-color: whitesmoke;
  padding: 16px;
  width: 50%;
  box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
}
@media all and (max-width: 992px) {
  .ProductDetails .container .row .right-col {
    margin: 160px 0 0;
    width: 100%;
  }
}
@media all and (max-width: 992px) and (max-width: 576px) {
  .ProductDetails .container .row .right-col {
    margin: 80px 0 0;
  }
}
.ProductDetails .container .row .right-col h3 {
  color: #9A5939;
  font-family: "Lora", serif;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 24px;
}
.ProductDetails .container .row .right-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .ProductDetails .container .row .right-col p {
    font-size: 16px;
  }
}
.ProductDetails .container .row .right-col ul {
  margin-left: 20px;
}
.ProductDetails .container .row .right-col img {
  max-width: 100%;
}

.spinning_loading {
  height: 40vh;
}