@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ShareButton {
  padding: 56px 100px;
}
@media all and (min-width: 2000px) {
  .ShareButton {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ShareButton {
    padding: 56px 80px;
  }
}
@media all and (max-width: 992px) {
  .ShareButton {
    padding: 56px 50px;
  }
}
@media all and (max-width: 768px) {
  .ShareButton {
    padding: 56px 32px;
  }
}
@media all and (max-width: 576px) {
  .ShareButton {
    padding: 36px 14px;
  }
}
.ShareButton h2 {
  margin-bottom: 10px !important;
  font-family: "Lora", serif;
  font-weight: 600;
  color: #9A5939;
  padding-bottom: 6px;
}
.ShareButton button {
  margin: 0 3px;
}
.ShareButton svg {
  transition: all 0.3s;
}
.ShareButton svg:hover {
  transform: scale(1.15);
}