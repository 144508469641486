@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BlogList {
  background-color: white;
  margin-top: -30px;
}
.BlogList .container {
  padding: 90px;
}
@media all and (max-width: 576px) {
  .BlogList .container {
    padding: 50px 16px;
  }
}
.BlogList .container .row {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex-wrap: wrap;
}
.BlogList .container .row .no-blogs {
  font-size: 20px;
  color: red;
  font-weight: 600;
  padding: 40px 0;
}
.BlogList .container .row .card {
  background-color: whitesmoke;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.2s ease-in;
  -webkit-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  padding: 6px;
  text-align: center;
}
.BlogList .container .row .card a {
  text-decoration: none;
  font-weight: 400;
}
.BlogList .container .row .card .img-container img {
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.BlogList .container .row .card .content {
  padding: 12px 0;
}
.BlogList .container .row .card .content a {
  text-decoration: none;
  color: #9A5939;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
  display: inline-block;
}
.BlogList .container .row .card .content p {
  color: black;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.BlogList .container .load_more {
  display: flex;
  justify-content: center;
  padding: 50px 0 0;
}
.BlogList .container .load_more .loadMore_btn {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #AF9E75;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  position: relative;
  border: none;
}
.BlogList .container .load_more .loadMore_btn::before {
  content: "";
  position: absolute;
  border: 2px solid #DDC7AA;
  width: 97%;
  height: 97%;
  top: -5px;
  left: -4px;
  opacity: 1;
  transition: 0.3s all;
}
.BlogList .container .load_more .loadMore_btn:hover {
  background-color: #0C0C0C;
}
.BlogList .container .load_more .loadMore_btn:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.BlogList .container .load_more .loadMore_btn:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.BlogList .container .load_more .loadMore_btn span {
  position: relative;
  left: -2px;
  top: -4px;
  transition: 0.3s all;
}
.BlogList .category-buttons {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px rgb(197, 197, 197);
  margin-bottom: 50px;
}
.BlogList .category-buttons button {
  border: none;
  padding: 18px 16px;
  margin: 0 1px;
  transition: 0.2s background-color;
  font-size: 16px;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .BlogList .category-buttons button {
    padding: 14px 6px;
    font-size: 12px;
  }
}
.BlogList .category-buttons button:hover {
  cursor: pointer;
}
.BlogList .category-buttons .active {
  background-color: #DDC7AA;
  border-bottom: 4px solid #AF9E75;
}
.BlogList .category-buttons .not-active {
  background-color: rgb(255, 255, 255);
}
.BlogList .category-buttons .not-active:hover {
  background-color: rgba(221, 199, 170, 0.6352941176);
}

.loading-container {
  padding: 200px 0;
  text-align: center;
}
.loading-container .lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-container .lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #AF9E75;
  animation: lds-grid 1.2s linear infinite;
}
.loading-container .lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.loading-container .lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.loading-container .lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.loading-container .lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.loading-container .lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.loading-container .lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.loading-container .lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.loading-container .lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.loading-container .lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}