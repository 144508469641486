@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.AboutIntro {
  position: relative;
  background: rgb(241, 241, 241);
  background: linear-gradient(170deg, rgb(241, 241, 241) 0%, rgb(255, 255, 255) 31%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
  overflow: hidden;
}
.AboutIntro .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .AboutIntro .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .AboutIntro .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .AboutIntro .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .AboutIntro .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .AboutIntro .container {
    padding: 50px 14px;
  }
}
.AboutIntro .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* .right-col animation styling */
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row {
    flex-direction: column;
  }
}
.AboutIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.AboutIntro .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .AboutIntro .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.AboutIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.AboutIntro .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .AboutIntro .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.AboutIntro .container .row .left-col {
  width: 60%;
  z-index: 2;
  position: relative;
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .left-col {
    width: 100%;
    text-align: center;
  }
}
.AboutIntro .container .row .left-col h1 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  color: #0C0C0C;
}
@media all and (max-width: 1200px) {
  .AboutIntro .container .row .left-col h1 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .AboutIntro .container .row .left-col h1 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .AboutIntro .container .row .left-col h1 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .left-col h1 {
    font-size: 24px;
  }
}
.AboutIntro .container .row .left-col h1 span {
  color: #C12328;
}
.AboutIntro .container .row .left-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .AboutIntro .container .row .left-col p {
    font-size: 16px;
  }
}
.AboutIntro .container .row .left-col .button a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #AF9E75;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  position: relative;
  position: relative;
}
.AboutIntro .container .row .left-col .button a::before {
  content: "";
  position: absolute;
  border: 2px solid #DDC7AA;
  width: 97%;
  height: 97%;
  top: -5px;
  left: -4px;
  opacity: 1;
  transition: 0.3s all;
}
.AboutIntro .container .row .left-col .button a:hover {
  background-color: #0C0C0C;
}
.AboutIntro .container .row .left-col .button a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.AboutIntro .container .row .left-col .button a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.AboutIntro .container .row .left-col .button a span {
  position: relative;
  left: -2px;
  top: -4px;
  transition: 0.3s all;
}
.AboutIntro .container .row .right-col {
  position: relative;
  z-index: 1;
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .right-col {
    margin-top: 60px;
  }
}
.AboutIntro .container .row .right-col img {
  width: 260px;
  height: 350px;
  object-fit: cover;
  display: inline-block;
  position: relative;
}
@media all and (max-width: 1200px) {
  .AboutIntro .container .row .right-col img {
    width: 220px;
    height: 310px;
  }
}
@media all and (max-width: 992px) {
  .AboutIntro .container .row .right-col img {
    width: 200px;
    height: 290px;
  }
}
@media all and (max-width: 768px) {
  .AboutIntro .container .row .right-col img {
    width: 160px;
    height: 250px;
  }
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .right-col img {
    width: 220px;
    height: 310px;
  }
}
.AboutIntro .container .row .right-col::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 300px;
  z-index: -1;
  height: 400px;
  object-fit: contain;
  transform: translateX(-30%) translateY(-20%);
  background: url("/public/img/dotted-simple-seamless-vector-pattern_547648-1241.jpg");
  opacity: 0.4;
}
@media all and (max-width: 1200px) {
  .AboutIntro .container .row .right-col::before {
    width: 260px;
    height: 360px;
  }
}
@media all and (max-width: 992px) {
  .AboutIntro .container .row .right-col::before {
    width: 240px;
    height: 340px;
  }
}
@media all and (max-width: 768px) {
  .AboutIntro .container .row .right-col::before {
    width: 200px;
    height: 300px;
  }
}
@media all and (max-width: 576px) {
  .AboutIntro .container .row .right-col::before {
    width: 260px;
    height: 300px;
  }
}