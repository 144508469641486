@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BannerSection {
  position: relative;
  margin-bottom: -3px;
}
.BannerSection .img-container img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.BannerSection .overlay {
  position: absolute;
  top: 0;
  height: 99%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0.3) 25%, rgba(12, 12, 12, 0) 90%, rgba(12, 12, 12, 0.2) 100%);
}
.BannerSection .text h2 {
  color: rgb(255, 255, 255);
  text-shadow: 2px black;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 54px;
  z-index: 1;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 992px) {
  .BannerSection .text h2 {
    font-size: 48px;
  }
}
@media all and (max-width: 768px) {
  .BannerSection .text h2 {
    font-size: 38px;
  }
}
@media all and (max-width: 576px) {
  .BannerSection .text h2 {
    font-size: 30px;
    white-space: nowrap;
  }
}