@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Overview {
  background: rgb(241, 241, 241);
  background: linear-gradient(170deg, rgb(241, 241, 241) 0%, rgb(255, 255, 255) 31%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
}
.Overview .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .Overview .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Overview .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Overview .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Overview .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Overview .container {
    padding: 50px 14px;
  }
}
.Overview .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* .right-col animation styling */
}
@media all and (max-width: 768px) {
  .Overview .container .row {
    flex-direction: column;
  }
}
.Overview .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.Overview .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .Overview .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .Overview .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.Overview .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.Overview .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .Overview .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .Overview .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.Overview .container .row .left-col {
  width: 60%;
}
@media all and (max-width: 768px) {
  .Overview .container .row .left-col {
    width: 100%;
  }
}
.Overview .container .row .left-col h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  color: #C12328;
}
@media all and (max-width: 1200px) {
  .Overview .container .row .left-col h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .Overview .container .row .left-col h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .Overview .container .row .left-col h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Overview .container .row .left-col h2 {
    font-size: 24px;
  }
}
.Overview .container .row .left-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .Overview .container .row .left-col p {
    font-size: 16px;
  }
}
.Overview .container .row .right-col {
  padding-left: 20px;
}
@media all and (max-width: 768px) {
  .Overview .container .row .right-col {
    padding-top: 50px;
    padding-left: 0;
  }
}
.Overview .container .row .right-col .plyr {
  width: 330px;
  height: 380px;
}
.Overview .container .row .right-col .plyr__controls__item.plyr__volume input {
  width: 40px !important;
  min-width: 30px !important;
}

:root {
  --plyr-color-main: #AF9E75;
}