@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BannerImgs {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  padding-top: 80px;
}
.BannerImgs .img-container img {
  width: 150px;
}
@media all and (max-width: 992px) {
  .BannerImgs .img-container img {
    width: 120px;
  }
}
@media all and (max-width: 768px) {
  .BannerImgs .img-container img {
    width: 100px;
  }
}
@media all and (max-width: 576px) {
  .BannerImgs .img-container img {
    width: 70px;
  }
}
@media all and (max-width: 360px) {
  .BannerImgs .img-container img {
    width: 60px;
  }
}