@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BlogIntro {
  background: rgb(241, 241, 241);
  background: linear-gradient(170deg, rgb(241, 241, 241) 0%, rgb(255, 255, 255) 31%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
}
.BlogIntro .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .BlogIntro .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .BlogIntro .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .BlogIntro .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .BlogIntro .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .BlogIntro .container {
    padding: 50px 14px;
  }
}
.BlogIntro .container p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .BlogIntro .container p {
    font-size: 16px;
  }
}