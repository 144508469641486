@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.GalleryList {
  overflow: hidden;
  background: rgb(241, 241, 241);
  background: linear-gradient(170deg, rgb(241, 241, 241) 0%, rgb(255, 255, 255) 31%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
}
.GalleryList .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .GalleryList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .GalleryList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .GalleryList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .GalleryList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .GalleryList .container {
    padding: 50px 14px;
  }
}
.GalleryList .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  text-align: center;
  color: #C12328;
  padding-bottom: 40px;
}
@media all and (max-width: 1200px) {
  .GalleryList .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .GalleryList .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .GalleryList .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .GalleryList .container h2 {
    font-size: 24px;
  }
}
.GalleryList .container .lg-react-element {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 60px;
  gap: 6px;
}
.GalleryList .container .lg-react-element .media-container {
  overflow: hidden;
}
.GalleryList .container .lg-react-element .media-container img {
  height: 250px;
  width: 350px;
  object-fit: cover;
  transition: all 0.3s;
  max-width: 99%;
}
.GalleryList .container .lg-react-element .media-container img:hover {
  cursor: pointer;
  filter: brightness(85%);
  transform: scale(1.05);
}
.GalleryList .container .lg-react-element .media-container .skeleton-loader {
  width: 350px;
  height: 250px;
  /* Set the height according to your needs */
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.1098039216) 25%, rgba(224, 224, 224, 0.1647058824) 50%, rgba(240, 240, 240, 0.1764705882) 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
.GalleryList .container .lg-react-element .media-container .lozad.lazy-loaded {
  filter: blur(0);
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.GalleryList .container .video-gallery .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 60px;
  gap: 6px;
}
.GalleryList .container .video-gallery video {
  width: 300px;
  height: 300px;
  margin: 16px 0;
}
.GalleryList .container .video-gallery .plyr {
  width: 300px;
  height: 300px;
}
.GalleryList .container .video-gallery .plyr__controls__item.plyr__volume input {
  width: 40px !important;
  min-width: 30px !important;
}