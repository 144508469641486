@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Herosection {
  background-color: white;
  overflow: hidden;
  position: relative;
}
.Herosection .container .row {
  display: flex;
}
.Herosection .container .row .image-container {
  height: 700px;
  overflow: hidden;
  width: 25%;
  position: relative;
}
@media all and (max-width: 992px) {
  .Herosection .container .row .image-container {
    height: 600px;
  }
}
@media all and (max-width: 768px) {
  .Herosection .container .row .image-container {
    height: 500px;
  }
}
@media all and (max-width: 576px) {
  .Herosection .container .row .image-container {
    width: 50%;
    height: 500px;
  }
}
.Herosection .container .row .image-container::before {
  content: "";
  position: absolute;
  display: inline-block;
  inset: 0;
  z-index: -1;
  opacity: 0;
  background-color: rgba(58, 58, 58, 0.6);
  transition: opacity 0.6s ease;
}
.Herosection .container .row .image-container:hover img {
  transform: scale(1.1);
}
.Herosection .container .row .image-container:hover .explore {
  opacity: 1;
}
.Herosection .container .row .image-container:hover::before {
  opacity: 1;
  z-index: 1;
}
.Herosection .container .row .image-container .explore {
  position: absolute;
  top: 75%;
  text-align: center;
  font-size: 18px;
  z-index: 5;
  opacity: 0;
  transition: 0.9s all;
}
.Herosection .container .row .image-container .explore h3 {
  font-weight: 400;
  color: white;
  padding: 14px 16px;
  z-index: 1;
  font-size: 30px;
  font-family: "Lora", serif;
  font-weight: 100;
}
@media all and (max-width: 1200px) {
  .Herosection .container .row .image-container .explore h3 {
    font-size: 26px;
  }
}
@media all and (max-width: 992px) {
  .Herosection .container .row .image-container .explore h3 {
    font-size: 24px;
  }
}
@media all and (max-width: 768px) {
  .Herosection .container .row .image-container .explore h3 {
    font-size: 16px;
  }
}
@media all and (max-width: 576px) {
  .Herosection .container .row .image-container .explore h3 {
    font-size: 20px;
  }
}
.Herosection .container .row .image-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s all;
}
@media all and (max-width: 576px) {
  .Herosection .container .row .image-container.second,
  .Herosection .container .row .image-container.third {
    display: none;
  }
}