* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* for customized scrollbar */
body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  
}

/* For Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #C12328;
  border-radius: 3px;
}

body::-webkit-scrollbar-track {
  background: rgb(19, 19, 19);

}
