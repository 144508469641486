@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ProductList {
  margin-top: -40px;
}
.ProductList .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .ProductList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ProductList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ProductList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ProductList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ProductList .container {
    padding: 50px 14px;
  }
}
.ProductList .container .category-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 6px;
}
.ProductList .container .category-buttons button {
  border: 1px solid rgba(154, 89, 57, 0.137254902);
  padding: 18px 16px;
  margin: 0 1px;
  transition: 0.2s background-color;
  font-size: 16px;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .ProductList .container .category-buttons button {
    padding: 14px 6px;
    font-size: 12px;
  }
}
.ProductList .container .category-buttons button:hover {
  cursor: pointer;
}
.ProductList .container .category-buttons .active {
  background-color: #DDC7AA;
  border-bottom: 4px solid #AF9E75;
}
.ProductList .container .category-buttons .not-active {
  background-color: rgb(255, 255, 255);
}
.ProductList .container .category-buttons .not-active:hover {
  background-color: rgba(221, 199, 170, 0.6352941176);
}
.ProductList .container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 50px 40px;
}
.ProductList .container .row a {
  text-decoration: none;
}
.ProductList .container .row .card {
  width: 300px;
  text-align: center;
  background-color: whitesmoke;
  padding: 6px;
  text-decoration: none;
  box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
}
.ProductList .container .row .card:hover .img-container img {
  transform: scale(1.2);
  filter: brightness(90%);
}
.ProductList .container .row .card .img-container {
  overflow: hidden;
}
.ProductList .container .row .card .img-container img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: 0.3s all;
}
.ProductList .container .row .card .content {
  padding: 8px 0;
}
.ProductList .container .row .card .content h3 {
  color: #9A5939;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.ProductList .container .row .no-products-message {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.ProductList .container .row .no-products-message h3 {
  font-size: 22px;
  color: #C12328;
}

.spinning_loading {
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 60vh;
}