@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Testimonials {
  position: relative;
  margin-top: -2px;
}
.Testimonials h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  text-align: center;
  position: relative;
  margin-bottom: 60px;
  color: #C12328;
}
@media all and (max-width: 1200px) {
  .Testimonials h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .Testimonials h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Testimonials h2 {
    font-size: 24px;
  }
}
.Testimonials h2::before {
  position: absolute;
  content: "";
  width: 160px;
  height: 4px;
  left: 50%;
  bottom: 2px;
  transform: translateX(-50%);
  background: rgb(193, 35, 40);
  background: radial-gradient(circle, rgb(193, 35, 40) 17%, rgba(193, 35, 40, 0) 100%);
}
.Testimonials .container {
  padding: 100px 100px;
  height: 100%;
}
@media all and (min-width: 2000px) {
  .Testimonials .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Testimonials .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Testimonials .container {
    padding: 50px 14px;
  }
}
.Testimonials .container .main-carousel {
  height: 100%;
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
  margin-left: -7%;
  background-color: rgb(236, 236, 236);
  color: #AF9E75;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -6%;
  }
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
  margin-right: -7%;
  background-color: rgb(236, 236, 236);
  color: #AF9E75;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -6%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    width: 36px;
    height: 36px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    display: none;
  }
}
.Testimonials .container .main-carousel .carousel-cell {
  width: 33%;
  padding-bottom: 60px;
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 50%;
  }
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 100%;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card {
  background-color: whitesmoke;
  padding: 20px 28px;
  border-radius: 6px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  text-align: center;
  min-height: 360px;
  position: relative;
  -webkit-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.Testimonials .container .main-carousel .carousel-cell .card .quote {
  font-size: 100px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.Testimonials .container .main-carousel .carousel-cell .card .quote i {
  font-size: 80px;
  color: rgba(175, 158, 117, 0.137254902);
}
.Testimonials .container .main-carousel .carousel-cell .card .content {
  padding: 20px 0;
}
.Testimonials .container .main-carousel .carousel-cell .card .content p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 15px !important;
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .carousel-cell .card .content p {
    font-size: 16px;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card h3 {
  font-size: 18px;
  font-weight: 500;
  color: #AF9E75;
  text-transform: capitalize;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container {
  position: relative;
  width: 100%;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
  margin: 6px 0;
}
.Testimonials .container .main-carousel .carousel-cell .card .img-container .google-icon {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: -10px;
  bottom: 4px;
}
.Testimonials .container .main-carousel .carousel-cell .card img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.Testimonials .container .main-carousel .carousel-cell .card .five-star-icon i {
  font-size: 13px;
  margin-bottom: 10px;
  color: #f8d50b;
  margin-right: 1px;
}
.Testimonials .container .main-carousel .flickity-page-dots .dot {
  height: 6px;
  width: 30px;
  margin: 2px;
  border-radius: 0;
  transition: all 0.6s ease-in;
  background-color: #AF9E75;
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .flickity-page-dots .dot {
    width: 14px;
    height: 6px;
  }
}