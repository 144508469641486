@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Footer {
  background-color: #161616;
}
.Footer .container {
  padding: 60px 100px;
}
@media all and (min-width: 2000px) {
  .Footer .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Footer .container {
    padding: 60px 100px;
  }
}
@media all and (max-width: 992px) {
  .Footer .container {
    padding: 60px 50px;
  }
}
@media all and (max-width: 768px) {
  .Footer .container {
    padding: 60px 32px;
  }
}
@media all and (max-width: 576px) {
  .Footer .container {
    padding: 30px 14px;
  }
}
.Footer .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px 40px;
}
.Footer .container .row .col .logo img {
  width: 80px;
}
@media all and (max-width: 576px) {
  .Footer .container .row .col .logo img {
    width: 80px;
  }
}
.Footer .container .row .col p {
  color: #AF9E75;
  font-weight: 500;
  font-family: "Lora", serif;
  font-weight: 600;
}
.Footer .container .row .col .quote {
  margin: 6px 0;
  font-weight: 700;
  font-size: 17px;
  font-family: "Lora", serif;
  font-weight: 700;
}
.Footer .container .row .col .social-media-icons a {
  display: inline-block;
  margin: 16px 10px 0;
}
.Footer .container .row .col .social-media-icons a i {
  color: #AF9E75;
  font-size: 24px;
  transition: 0.3s all;
}
.Footer .container .row .col .social-media-icons a i:hover {
  transform: scale(1.15);
  color: white;
}
.Footer .container .row .col li {
  list-style: none;
  padding: 9px 0;
}
.Footer .container .row .col li a {
  text-decoration: none;
  color: #AF9E75;
  font-family: "Lora", serif;
  font-weight: 600;
  transition: 0.3s all;
  position: relative;
  text-transform: uppercase;
}
.Footer .container .row .col li a::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  opacity: 1;
  transform: scale(0%);
  transform-origin: left;
  transition: 0.4s all;
  border: 1px solid #C12328;
}
.Footer .container .row .col li a:hover {
  color: white;
}
.Footer .container .row .col li a:hover::before {
  content: "";
  position: absolute;
  left: 0;
  transform: scale(100%);
  border: 1px solid #C12328;
}
.Footer .container .row .col.right {
  display: flex;
}
.Footer .container .row .col.right .icon {
  padding-right: 8px;
}
.Footer .container .row .col.right .icon i {
  color: #AF9E75;
}
.Footer .copyright {
  text-align: center;
  color: rgba(175, 158, 117, 0.6156862745);
  padding: 10px 0;
  font-weight: 200;
}