@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.FeaturedProducts .container {
  padding: 56px 100px;
}
@media all and (min-width: 2000px) {
  .FeaturedProducts .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .FeaturedProducts .container {
    padding: 56px 80px;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container {
    padding: 56px 50px;
  }
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container {
    padding: 56px 32px;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container {
    padding: 36px 14px;
  }
}
.FeaturedProducts .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  color: #C12328;
  text-align: center;
  position: relative;
}
@media all and (max-width: 1200px) {
  .FeaturedProducts .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container h2 {
    font-size: 24px;
  }
}
.FeaturedProducts .container h2::before {
  position: absolute;
  content: "";
  width: 160px;
  height: 4px;
  left: 50%;
  bottom: 2px;
  transform: translateX(-50%);
  background: rgb(193, 35, 40);
  background: radial-gradient(circle, rgb(193, 35, 40) 17%, rgba(193, 35, 40, 0) 100%);
}
.FeaturedProducts .container .row .main-carousel {
  padding: 60px 0;
  cursor: pointer;
}
.FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.previous {
  margin-left: -7%;
  background-color: rgb(236, 236, 236);
  color: #AF9E75;
}
@media all and (min-width: 1800px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -4%;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -6%;
  }
}
.FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.next {
  margin-right: -7%;
  background-color: rgb(236, 236, 236);
  color: #AF9E75;
}
@media all and (min-width: 1800px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -4%;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -6%;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.next {
    width: 36px;
    height: 36px;
  }
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .FeaturedProducts .container .row .main-carousel .flickity-button.flickity-prev-next-button.next {
    display: none;
  }
}
.FeaturedProducts .container .row .main-carousel .carousel-cell {
  width: 33.33%;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10px;
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container .row .main-carousel .carousel-cell {
    width: 50%;
  }
}
@media all and (max-width: 568px) {
  .FeaturedProducts .container .row .main-carousel .carousel-cell {
    width: 100%;
  }
}
.FeaturedProducts .container .row .main-carousel .carousel-cell .card {
  width: 250px;
  text-align: center;
  background-color: whitesmoke;
  padding: 6px;
  text-decoration: none;
  box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
}
.FeaturedProducts .container .row .main-carousel .carousel-cell .card a {
  text-decoration: none;
}
.FeaturedProducts .container .row .main-carousel .carousel-cell .card .img-container img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.FeaturedProducts .container .row .main-carousel .carousel-cell .card .content {
  padding: 8px 0;
}
.FeaturedProducts .container .row .main-carousel .carousel-cell .card .content h3 {
  color: #9A5939;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.FeaturedProducts .container .row .main-carousel .flickity-page-dots .dot {
  height: 6px;
  width: 30px;
  margin: 2px;
  border-radius: 0;
  transition: all 0.6s ease-in;
  background-color: #AF9E75;
}
@media all and (max-width: 568px) {
  .FeaturedProducts .container .row .main-carousel .flickity-page-dots .dot {
    width: 14px;
    height: 6px;
  }
}
.FeaturedProducts .container .row .button {
  display: flex;
  justify-content: center;
  margin: 80px 0 0;
}
.FeaturedProducts .container .row .button .view-more {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #AF9E75;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  position: relative;
}
.FeaturedProducts .container .row .button .view-more::before {
  content: "";
  position: absolute;
  border: 2px solid #DDC7AA;
  width: 97%;
  height: 97%;
  top: -5px;
  left: -4px;
  opacity: 1;
  transition: 0.3s all;
}
.FeaturedProducts .container .row .button .view-more:hover {
  background-color: #0C0C0C;
}
.FeaturedProducts .container .row .button .view-more:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.FeaturedProducts .container .row .button .view-more:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.FeaturedProducts .container .row .button .view-more span {
  position: relative;
  left: -2px;
  top: -4px;
  transition: 0.3s all;
}