@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.GetInTouch {
  position: relative;
  background-color: white;
  z-index: 5;
  background: rgb(241, 241, 241);
  background: linear-gradient(170deg, rgb(241, 241, 241) 0%, rgb(255, 255, 255) 31%, rgb(255, 255, 255) 66%, rgb(255, 255, 255) 100%);
}
.GetInTouch .container {
  padding: 100px 100px;
  /* .right-col animation styling */
}
@media all and (min-width: 2000px) {
  .GetInTouch .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .GetInTouch .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .GetInTouch .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container {
    padding: 50px 14px;
  }
}
.GetInTouch .container .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.GetInTouch .container .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .GetInTouch .container .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .GetInTouch .container .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.GetInTouch .container .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.GetInTouch .container .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .GetInTouch .container .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .GetInTouch .container .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.GetInTouch .container h2 {
  text-align: center;
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #0C0C0C;
  color: #C12328;
}
@media all and (max-width: 1200px) {
  .GetInTouch .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .GetInTouch .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container h2 {
    font-size: 24px;
  }
}
.GetInTouch .container .row {
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
  padding: 50px 0 40px;
  margin-top: 50px;
  -webkit-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row {
    flex-direction: column;
  }
}
.GetInTouch .container .row .col {
  padding: 0 6vw;
  text-align: left;
}
@media all and (max-width: 1200px) {
  .GetInTouch .container .row .col {
    padding: 0 4vw;
  }
}
@media all and (max-width: 992px) {
  .GetInTouch .container .row .col {
    padding: 0 3vw;
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col {
    padding: 2vw 3vw;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col {
    padding: 2vw 0;
  }
}
.GetInTouch .container .row .col .text {
  display: flex;
  padding-bottom: 20px;
  margin-left: -8px;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col .text {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col .text {
    padding-bottom: 26px;
  }
}
.GetInTouch .container .row .col .text span {
  padding-right: 16px;
  font-size: 18px;
  color: #AF9E75;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col .text span {
    display: flex;
    justify-content: center;
    padding-right: 6px;
  }
}
.GetInTouch .container .row .col .text p {
  color: #AF9E75;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 130%;
}
.GetInTouch .container .row .col .text p:hover {
  text-decoration: underline;
}
.GetInTouch .container .row .col .text .address:hover {
  text-decoration: none;
}
.GetInTouch .container .row .col .text a {
  text-decoration: none;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.GetInTouch .container .row .col .text a:hover {
  text-decoration: underline;
}
.GetInTouch .container .row .col h3 {
  color: #AF9E75;
  font-size: 26px;
  padding-bottom: 5px;
  margin-left: 24px;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col h3 {
    margin-left: 0;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col h3 {
    font-size: 20px;
  }
}
.GetInTouch .container .row .col.form form div {
  padding: 10px;
}
.GetInTouch .container .row .col.form form div input {
  height: 40px;
  width: 350px;
  color: white;
  padding: 5px;
  background-color: #AF9E75;
  border: none;
  border: 1px solid rgb(128, 67, 67);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.GetInTouch .container .row .col.form form div input::placeholder {
  padding-left: 10px;
  color: white;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.GetInTouch .container .row .col.form form div input:focus {
  background-color: #AF9E75;
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col.form form div input {
    width: 280px;
  }
}
@media all and (max-width: 378px) {
  .GetInTouch .container .row .col.form form div input {
    width: 200px;
  }
}
.GetInTouch .container .row .col.form form div textarea {
  height: 80px;
  width: 350px;
  color: white;
  background-color: #AF9E75;
  border: none;
  border: 1px solid rgb(128, 67, 67);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.GetInTouch .container .row .col.form form div textarea::placeholder {
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 500;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.GetInTouch .container .row .col.form form div textarea:focus {
  background-color: #AF9E75;
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col.form form div textarea {
    width: 280px;
  }
}
@media all and (max-width: 378px) {
  .GetInTouch .container .row .col.form form div textarea {
    width: 200px;
  }
}
.GetInTouch .container .row .col.form form button {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #AF9E75;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  position: relative;
  background-color: #AF9E75;
  padding: 10px 16px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  margin-left: 10px;
  border: none;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.GetInTouch .container .row .col.form form button::before {
  content: "";
  position: absolute;
  border: 2px solid #DDC7AA;
  width: 97%;
  height: 97%;
  top: -5px;
  left: -4px;
  opacity: 1;
  transition: 0.3s all;
}
.GetInTouch .container .row .col.form form button:hover {
  background-color: #0C0C0C;
}
.GetInTouch .container .row .col.form form button:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.GetInTouch .container .row .col.form form button:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.GetInTouch .container .row .col.form form button span {
  position: relative;
  left: -2px;
  top: -4px;
  transition: 0.3s all;
}
.GetInTouch .container .row .col.form form .successMessage {
  display: none;
}
.GetInTouch .container .row .col.form form .successMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-left: 10px;
}
.GetInTouch .container .row .col.form form .errorMessage {
  display: none;
}
.GetInTouch .container .row .col.form form .errorMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(255, 74, 74);
  font-size: 16px;
}